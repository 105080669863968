<template>
    <div>
        <page-title>{{ title }}</page-title>
        <user-route-tabs class="mb-4" :user-id="userId"></user-route-tabs>
        <canteen-balances-table :user-id="userId"></canteen-balances-table>
    </div>
</template>

<script>
import SelectApiClienType from '@/api/SelectApiClientType'
import TitleMixinGenerator from '@/components/mixins/page-title/TitleMixinGenerator'
import UserRouteTabs from '@/components/pages/users/RouteTabs'
import CanteenBalancesTable from '@/components/pages/balances/CanteenBalancesTable'

export default {
    components: { UserRouteTabs, CanteenBalancesTable },
    mixins: [TitleMixinGenerator(SelectApiClienType.USER, 'userId')],
    props: {
        userId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
