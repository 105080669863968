export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'onlyOpen',
                    inputType: 'checkbox',
                    label: 'labels.only_open',
                    value: '1',
                    default: true,
                },
            ]
        },
    },
}
