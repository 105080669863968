<template>
    <div class="admin-data-iterator">
        <v-row justify="space-between">
            <v-col class="pt-0">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    hide-search-box
                ></table-filters>
            </v-col>
        </v-row>

        <overlay :show="busy">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                :action-width="140"
                row-action
                @onRowSelected="onRowSelected"
            >
                <!-- allowance.name -->
                <template v-slot:[`item.canteenName`]="{ item }">
                    {{ item.canteenName }}<br />
                    <span class="small">#{{ item.canteenId }}</span>
                </template>

                <!-- credit -->
                <template v-slot:[`item.budget`]="{ item }">
                    <span v-show="editedItem !== item">
                        {{ item.budget | currency }}
                    </span>
                    <v-form
                        v-show="editedItem === item"
                        v-model="isValid"
                        @submit.prevent="submit(item)"
                    >
                        <currency-input
                            :key="item['@id']"
                            v-model="item.budget"
                            v-validate="'required|min_value:0|decimal:2'"
                            :name="`${item['@id']}.budget`"
                            label="labels.balance"
                            hide-details="auto"
                            single-line
                            class="pt-0 mt-0"
                            :error-messages="
                                errors.collect(`${item['@id']}.budget`)
                            "
                            :data-vv-as="$t('labels.balance')"
                        ></currency-input>
                    </v-form>
                </template>

                <!-- budget -->
                <template v-slot:[`item.budgetLimit`]="{ item }">
                    {{ item.budgetLimit | currency }}
                </template>

                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <template v-if="item.budgetLimit && editedItem !== item">
                        <edit-button
                            @execute="edit(item, 'budget')"
                        ></edit-button>
                    </template>
                    <template v-else>
                        <v-btn
                            color="primary"
                            class="white-text"
                            :disabled="!isValid"
                            icon
                            @click="submit(item)"
                        >
                            <v-icon>check</v-icon>
                        </v-btn>
                        <v-btn
                            class="ml-3"
                            icon
                            @click="cancel(item, 'budget')"
                        >
                            <v-icon>cancel</v-icon>
                        </v-btn>
                    </template>
                </template>
            </d-table>

            <portal to="footer-right">
                <div class="d-flex justify-end my-n1">
                    <div class="pt-1">
                        <v-select
                            v-model="itemsPerPage"
                            class="d-inline-block mr-2 per-page-select"
                            hide-details="auto"
                            dense
                            :items="perPageChoices"
                            :rules="[(v) => !!v || 'Item is required']"
                        ></v-select>
                    </div>
                    <div v-if="Math.ceil(total / itemsPerPage) > 1">
                        <v-pagination
                            v-model="page"
                            :length="length"
                            total-visible="7"
                            color="primary"
                        ></v-pagination>
                    </div>
                </div>
            </portal>
        </overlay>
    </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import { mapGetters } from 'vuex'
import { DEFAULT_PERPAGE, PERPAGE_CHOICES } from '@/defaults'
import { EventBus } from '@/plugins/events'
import Overlay from '@/components/overlay/Overlay'
import configType from '@/store/type/configType'
import canteenType from '@/store/type/canteenType'
import CompanyCloudcanteens from '@/api/RestApi/CompanyCloudcanteens'
import Notification from '@/services/Notification/Notification'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import EditInList from '@/components/mixins/EditInList'
import BalancesHeaders from './mixins/CanteenBalancesHeaders'
import ListFilters from './mixins/CanteenBalancesListFilters'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'

export default {
    components: { Overlay, CurrencyInput },
    mixins: [DataTableMixin, EditInList, BalancesHeaders, ListFilters],
    props: {
        userId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            total: 0,
            page: 1,
            busy: false,
            items: [],
            sentQuery: {},
            updateItemsTimeout: null,
            itemsPerPage: 50,
            filters: {
                onlyOpen: '1',
            },
        }
    },
    computed: {
        ...mapGetters({
            currency: configType.getters.CURRENCY_CODE,
        }),
        perPageChoices() {
            return PERPAGE_CHOICES
        },
    },
    watch: {
        filters: {
            deep: true,
            immediate: true,
            handler(newFilters, oldFilters) {
                if (isEqual(newFilters, oldFilters)) return
                if (
                    newFilters?.page === oldFilters?.page &&
                    newFilters?.page !== 1
                )
                    this.page = 1

                this.updateItems()
            },
        },
        itemsPerPage(value) {
            this.filters = {
                ...this.filters,
                ...{ itemsPerPage: value },
            }
        },
        page(value) {
            this.filters = {
                ...this.filters,
                ...{ page: value },
            }
        },
    },
    created() {
        EventBus.$on('updateList', this.updateList)

        this.itemsPerPage = DEFAULT_PERPAGE
        if (
            !isEqual(this.$route.query, {
                ...this.filters,
                ...{
                    page: 1,
                    itemsPerPage: DEFAULT_PERPAGE,
                },
            })
        ) {
            const query = {
                ...this.filters,
                ...{
                    page: 1,
                    itemsPerPage: DEFAULT_PERPAGE,
                },
                ...this.$route.query,
            }

            this.page = parseInt(query.page)
            this.itemsPerPage = parseInt(query.itemsPerPage)
            this.filters = query
        }
    },
    beforeDestroy() {
        EventBus.$off('updateList', this.updateList)
    },
    methods: {
        getTitle(item) {
            return item.canteenName
        },
        updateList(api) {
            if (api === this.api) this.updateItems(true)
        },
        updateItems(update = false, onlyReload = false) {
            return new Promise((resolve) => {
                const query = { ...this.filters, ...this.fixedFilters }
                if (
                    isEqual(query, this.sentQuery) &&
                    !update &&
                    this.items.length
                ) {
                    resolve()
                    return
                }

                this.busy = true
                this.sentQuery = query

                if (this.updateItemsTimeout !== null)
                    clearTimeout(this.updateItemsTimeout)
                this.updateItemsTimeout = setTimeout(() => {
                    if (
                        !this.disableQueryParams &&
                        !onlyReload &&
                        !isEqual(this.$route.query, this.filters)
                    ) {
                        this.$router.replace({
                            query: this.filters,
                        })
                    }

                    this.$store
                        .dispatch(canteenType.actions.LOAD_CANTEN_BALANCES, {
                            userId: this.userId,
                            query,
                        })
                        .then((balances) => {
                            this.items = balances
                        })
                        .finally(() => {
                            resolve()
                            this.busy = false
                        })
                }, 100)
            })
        },

        submit(item) {
            this.$validator.validate().then((isValid) => {
                if (isValid) {
                    this.busy = true

                    CompanyCloudcanteens.updateUserBudget(
                        this.editedItem.canteenId,
                        this.editedItem.budget,
                        this.userId
                    )
                        .then(() => {
                            Notification.entityUpdated(
                                this.entity,
                                this.getTitle(item)
                            )
                            this.editedItem = null
                        })
                        .catch(() => {
                            Notification.formError()
                        })
                        .finally(() => {
                            this.busy = false
                        })
                }
            })
        },
    },
}
</script>
